'use es6';

import Immutable from 'immutable';
import I18n from 'I18n';
import Raven from 'raven-js';
import PortalIdParser from 'PortalIdParser';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import * as FilesApi from 'FileManagerCore/api/Files';
import { checkLocalFilesForDuplicates, compareLocalFilesWithUploadResponse } from 'FileManagerCore/api/fileDuplication';
import { ARCHIVE_FILE_ATTEMPTED, ARCHIVE_FILE_FAILED, ARCHIVE_FILE_SUCCEEDED, UNARCHIVE_FILE_ATTEMPTED, UNARCHIVE_FILE_FAILED, UNARCHIVE_FILE_SUCCEEDED, UPDATE_FILE_EXPIRATION_ATTEMPTED, UPDATE_FILE_EXPIRATION_FAILED, UPDATE_FILE_EXPIRATION_SUCCEEDED } from 'FileManagerCore/actions/ActionTypes';
import * as Actions from 'FileManagerCore/actions/Files';
import { searchFiles } from 'FileManagerCore/actions/FileSearch';
import { reportError } from 'FileManagerCore/utils/logging';
import { getFulfilled, getRejected } from 'FileManagerCore/utils/promise';
import { getFileUploadSuccessAlert, getFailureNotification, getSuccessNotification } from 'FileManagerCore/utils/notifications';
import { getType } from 'FileManagerCore/utils/file';
import { UploadValidationError } from 'FileManagerCore/types/redux/Files';
import { getParentFolder } from 'FileManagerCore/utils/FoldersAndFiles';
import { getFoldersById } from 'FileManagerCore/selectors/Folders';
import { UploadSource } from 'FileManagerCore/Constants';
import { FILE_MANAGER_DASHBOARD_DEFAULT_ACCESS } from '../Constants';
import { EXPORT_FILES_ATTEMPTED, EXPORT_FILES_FAILED, EXPORT_FILES_SUCCEEDED } from './ActionTypes';
import UsageTracker from '../utils/usageTracker';
import { getExportSuccessNotification, getExportFailureNotification } from '../utils/notifications';
import { getFilesQueryFromOptions } from '../utils/getFilesQueryFromOptions';
import { removeFileExpiration, setFileExpiration } from '../api/File';
const trackExportFilesInteraction = selectedFilesAndFolders => {
  UsageTracker.track('fileManagerManageFiles', {
    action: selectedFilesAndFolders ? 'export selection' : 'export'
  });
};
export function exportFiles(emails, selectedFilesAndFolders) {
  return dispatch => {
    dispatch({
      type: EXPORT_FILES_ATTEMPTED,
      emails
    });
    trackExportFilesInteraction(selectedFilesAndFolders);
    FilesApi.exportFiles(emails, selectedFilesAndFolders).then(data => {
      dispatch({
        type: EXPORT_FILES_SUCCEEDED,
        meta: getExportSuccessNotification(),
        data
      });
    }, error => {
      dispatch({
        type: EXPORT_FILES_FAILED,
        meta: getExportFailureNotification(),
        error
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function archiveFile(fileToArchive) {
  return dispatch => {
    dispatch({
      type: ARCHIVE_FILE_ATTEMPTED,
      file: fileToArchive
    });
    UsageTracker.track('fileManagerManageFiles', {
      action: 'archive',
      fileType: getType(fileToArchive.get('extension')),
      fileExtension: fileToArchive.get('extension')
    });
    FilesApi.archiveFile(fileToArchive.get('id')).then(archivedFile => {
      dispatch({
        type: ARCHIVE_FILE_SUCCEEDED,
        file: archivedFile
      });
    }, error => {
      dispatch({
        type: ARCHIVE_FILE_FAILED,
        meta: {
          notification: {
            type: 'danger',
            titleText: I18n.text('FileManagerDashboard.notifications.archive.error.title'),
            message: I18n.text('FileManagerDashboard.notifications.archive.error.message', {
              filename: fileToArchive.get('name')
            })
          }
        },
        file: fileToArchive,
        error
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function unarchiveFile(fileToUnarchive) {
  return dispatch => {
    dispatch({
      type: UNARCHIVE_FILE_ATTEMPTED,
      file: fileToUnarchive
    });
    UsageTracker.track('fileManagerManageFiles', {
      action: 'unarchive',
      fileType: getType(fileToUnarchive.get('extension')),
      fileExtension: fileToUnarchive.get('extension')
    });
    FilesApi.unarchiveFile(fileToUnarchive.get('id')).then(unarchivedFile => {
      dispatch({
        type: UNARCHIVE_FILE_SUCCEEDED,
        file: unarchivedFile
      });
    }, error => {
      dispatch({
        type: UNARCHIVE_FILE_FAILED,
        meta: {
          notification: {
            type: 'danger',
            titleText: I18n.text('FileManagerDashboard.notifications.unarchive.error.title'),
            message: I18n.text('FileManagerDashboard.notifications.unarchive.error.message', {
              filename: fileToUnarchive.get('name')
            })
          }
        },
        fileToUnarchive,
        error
      });
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
}
export function removeFile(file) {
  return dispatch => {
    UsageTracker.track('fileManagerManageFiles', {
      action: 'delete',
      fileType: getType(file.get('extension')),
      fileExtension: file.get('extension')
    });
    dispatch(Actions.removeFile(file));
  };
}
export function fetchFiles(options = {}) {
  const {
    currentFolder
  } = options;
  return (dispatch, getState) => {
    var _options$page, _options$archived;
    const folderId = options.folderId || currentFolder && currentFolder.get('id');
    const query = getFilesQueryFromOptions(Object.assign({}, options, {
      folderId,
      page: (_options$page = options.page) !== null && _options$page !== void 0 ? _options$page : 1,
      archived: (_options$archived = options.archived) !== null && _options$archived !== void 0 ? _options$archived : false
    }), getState(), true);
    return dispatch(searchFiles(query));
  };
}
export function moveFile(file, destination) {
  return dispatch => {
    UsageTracker.track('fileManagerManageFiles', {
      action: 'move',
      fileType: getType(file.get('extension')),
      fileExtension: file.get('extension')
    });
    dispatch(Actions.moveFile(file, destination));
  };
}
export const renameFile = (file, newName) => dispatch => {
  UsageTracker.track('fileManagerManageFiles', {
    action: 'rename',
    fileType: getType(file.get('extension')),
    fileExtension: file.get('extension')
  });
  dispatch(Actions.renameFile(file, newName)).catch(err => console.warn('Rename file failed', err));
};
export function replaceFile(existingFile, localFile) {
  return dispatch => {
    UsageTracker.track('fileManagerManageFiles', {
      action: 'replace',
      fileType: getType(existingFile.get('extension')),
      fileExtension: existingFile.get('extension')
    });
    dispatch(Actions.replaceFile(existingFile, localFile));
  };
}
export const uploadDashboardFiles = (fileList, options = {}) => (dispatch, getState) => {
  const files = Array.prototype.slice.call(fileList);
  options.access = FILE_MANAGER_DASHBOARD_DEFAULT_ACCESS;
  options.uploadSource = UploadSource.DASHBOARD;
  const duplicateCheckPromise = checkLocalFilesForDuplicates(files);
  dispatch(Actions.uploadFiles(files, options)).then(results => {
    const uploadedFiles = getFulfilled(results);
    const uploadErrors = getRejected(results);
    if (duplicateCheckPromise) {
      duplicateCheckPromise.then(resp => {
        compareLocalFilesWithUploadResponse(resp, results, UsageTracker);
      }).catch(err => {
        reportError(err, {
          type: 'compare-duplicate-hash'
        });
      });
    }
    const getIsActionUnAuthorized = errorData => {
      return errorData.status && errorData.status === 403;
    };
    if (uploadErrors.find(err => getIsActionUnAuthorized(err))) {
      setTimeout(() => window.location.reload(), 2000);
      return;
    }
    if (uploadedFiles.length) {
      const firstUploadedFile = uploadedFiles[0];
      FloatingAlertStore.addAlert(getFileUploadSuccessAlert(FILE_MANAGER_DASHBOARD_DEFAULT_ACCESS, uploadedFiles.length, {
        fileDetailsLink: `/files/${PortalIdParser.get()}?showDetails=${firstUploadedFile.get('id')}`,
        fileName: `${firstUploadedFile.get('name')}.${firstUploadedFile.get('extension')}`,
        parentFolderName: getParentFolder(firstUploadedFile, getFoldersById(getState())).get('name')
      }));
    }
  }).catch(err => {
    if (err instanceof UploadValidationError) {
      return;
    }
    Raven.captureException(err, {
      fingerprint: ['uploadDashboardFiles']
    });
  });
};
export const updateFileExpiration = (fileId, ttl) => dispatch => {
  dispatch({
    type: UPDATE_FILE_EXPIRATION_ATTEMPTED,
    fileId
  });
  UsageTracker.track('fileManagerManageFiles', {
    action: ttl === 0 ? 'remove-expiration' : 'update-expiration',
    ttl
  });
  const apiCall = ttl === 0 ? removeFileExpiration : setFileExpiration;
  apiCall(fileId, ttl).then(resp => {
    const file = Immutable.Map(resp);
    dispatch({
      type: UPDATE_FILE_EXPIRATION_SUCCEEDED,
      fileId,
      file,
      ttl,
      meta: {
        notification: getSuccessNotification('updateFileExpiration', {
          showTitle: false
        })
      }
    });
  }).catch(err => {
    reportError(err, {
      type: UPDATE_FILE_EXPIRATION_FAILED
    });
    dispatch({
      type: UPDATE_FILE_EXPIRATION_FAILED,
      fileId,
      ttl,
      meta: {
        notification: getFailureNotification('updateFileExpiration', {
          showTitle: false
        })
      }
    });
    throw err;
  });
};