import getFormattedOrderByUsingSortDirection from './getFormattedOrderByUsingSortDirection';
import { MINIMUM_FETCH_FILES_AND_FOLDERS_LIMIT, ObjectCategory } from 'FileManagerCore/Constants';
import { MAX_SEARCH_QUERY_LENGTH } from 'FileManagerCore/constants/CrmPlatformLimits';
import { getPageSize } from '../selectors/pageSize';
import getFetchedFilesAndFoldersLimit from './getFetchedFilesAndFoldersLimit';
import { getFilesAndFoldersTotal, getTableFoldersAndFiles } from '../selectors/FilesAndFoldersTable';
const getIsFilteringFiles = query => Boolean(query.archived || query.type);
export const getFilesAndFoldersQueryFromOptions = (options, state, initialFetch = false) => {
  const {
    search: searchQuery,
    archived,
    page,
    orderBy,
    sortDirection,
    folderId,
    fileType,
    isHublVideo
  } = options;
  const pageSize = getPageSize(state);
  const query = {
    archived,
    limit: getFetchedFilesAndFoldersLimit(page, pageSize),
    order_by: getFormattedOrderByUsingSortDirection(orderBy, sortDirection)
  };
  if (isHublVideo !== undefined) {
    query.isHublVideo = isHublVideo;
  }
  if (!initialFetch) {
    const filesAndFoldersTotal = getFilesAndFoldersTotal(state);
    const fetchedFilesAndFoldersFilesCount = getTableFoldersAndFiles(state).objects.size;
    const last = page * pageSize;
    const needed = last - fetchedFilesAndFoldersFilesCount;
    if (needed <= 0 || fetchedFilesAndFoldersFilesCount >= filesAndFoldersTotal) {
      return false;
    }
    query.offset = fetchedFilesAndFoldersFilesCount;
    query.limit = needed < MINIMUM_FETCH_FILES_AND_FOLDERS_LIMIT ? MINIMUM_FETCH_FILES_AND_FOLDERS_LIMIT : needed;
  }
  if (searchQuery) {
    query.search = searchQuery.substring(0, MAX_SEARCH_QUERY_LENGTH);
  } else {
    query.folder_id = folderId || 'None';
  }

  // File type filtering is not scoped to the current folder
  if (fileType) {
    query.type = fileType;
    delete query.folder_id;
  }

  // If we're filtering by file-only attributes, only fetch files
  if (getIsFilteringFiles(query)) {
    query.category = ObjectCategory.FILE;
  }
  return query;
};