import { RequestStatus } from 'FileManagerCore/Constants';
import * as DashboardActionTypes from '../actions/ActionTypes';
const defaultState = {
  domains: [],
  status: RequestStatus.UNINITIALIZED
};
export default function Domains(state = defaultState, action) {
  const {
    type,
    domains
  } = action;
  switch (type) {
    case DashboardActionTypes.FETCH_DOMAINS_ATTEMPTED:
      return Object.assign({}, state, {
        status: RequestStatus.PENDING
      });
    case DashboardActionTypes.FETCH_DOMAINS_SUCCEEDED:
      return Object.assign({}, state, {
        status: RequestStatus.SUCCEEDED,
        domains
      });
    default:
      return state;
  }
}