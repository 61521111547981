import { createSelector } from 'reselect';
import { SUBTITLE_TYPES, RequestStatus } from '../Constants';
import { INVALID_FILE_FORMAT } from '../enums/FileValidationErrors';
import { splitNameAndExtension } from '../utils/file';
export const getSubtitlesState = state => state.videoSubtitles;
export const getFileSubtitlesFetchRequestStatusByVideoFileId = (state, videoFileId) => {
  return getSubtitlesState(state).get('fetchSubtitlesRequestStatusByVideoFileId').get(videoFileId) || RequestStatus.UNINITIALIZED;
};
export const getReplaceSubtitleFileSubtitleRequestStatus = createSelector([getSubtitlesState], videoSubtitles => videoSubtitles.get('updateSubtitlesRequestStatus'));
export const selectIsSubtitleUploadPending = createSelector([getSubtitlesState], subtitles => subtitles.get('updateSubtitlesRequestStatus') === RequestStatus.PENDING);
export const getRemoveSubtitleFileRequestStatus = createSelector([getSubtitlesState], videoSubtitles => videoSubtitles.get('removeSubtitleFileRequestStatus'));
export function getSubtitles(state) {
  return state.videoSubtitles.get('locallyLoadedSubtitles');
}
export function getSubtitlesByVideoFileId(state, videoFileId) {
  return state.videoSubtitles.getIn(['locallyLoadedSubtitles', videoFileId]);
}
export function getEditedSubtitleFile(state) {
  return state.videoSubtitles.get('editedSubtitleFile');
}
export function getEditedSubtitleLanguage(state) {
  return state.videoSubtitles.get('editedLanguage');
}
export const getMaybeValidationError = createSelector([getEditedSubtitleFile], editedSubtitleFile => {
  if (!editedSubtitleFile) {
    return null;
  }
  if (!editedSubtitleFile.type) {
    const {
      extension
    } = splitNameAndExtension(editedSubtitleFile);
    if (extension !== 'vtt') {
      return INVALID_FILE_FORMAT;
    }
  } else if (!SUBTITLE_TYPES.includes(editedSubtitleFile.type)) {
    return INVALID_FILE_FORMAT;
  }
  return null;
});
export const selectFetchGenerationOptInRequestStatus = createSelector([getSubtitlesState], subtitlesState => subtitlesState.get('fetchGenerationOptInRequestStatus'));
export const selectIsSubtitleGenerationEnabled = createSelector([getSubtitlesState], subtitlesState => subtitlesState.get('generationEnabled'));