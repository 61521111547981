export const DrawerTypes = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
  FILE: 'FILE',
  AUDIO: 'AUDIO'
};
export const PickerTypes = {
  PANEL: 'PANEL',
  SIDE_MODAL: 'SIDE_MODAL'
};
export const PickerPanels = {
  BROWSE: 'BROWSE',
  SEARCH: 'SEARCH',
  STOCK_IMAGES: 'STOCK_IMAGES',
  FOLDER: 'FOLDER',
  FROM_URL: 'FROM_URL',
  FILE_DETAIL: 'FILE_DETAIL',
  BULK_IMPORT_FROM_URL: 'BULK_IMPORT_FROM_URL',
  IMAGE_GENERATION: 'IMAGE_GENERATION',
  LANDING: 'LANDING'
};
export const IframeComponentTypes = {
  FILE_PICKER: 'FILE_PICKER',
  IMAGE_EDITOR: 'IMAGE_EDITOR'
};
export const FileTypes = {
  IMG: 'IMG',
  MOVIE: 'MOVIE',
  DOCUMENT: 'DOCUMENT',
  AUDIO: 'AUDIO',
  OTHER: 'OTHER'
};
export const FMMessageType = 'FILE_MANAGER';
export const MessageActions = {
  IFRAME_READY: 'IFRAME_READY',
  OPEN_PICKER: 'OPEN_PICKER',
  OPEN_IMAGE_EDITOR: 'OPEN_IMAGE_EDITOR',
  OPEN_PICKER_COMPLETE: 'OPEN_PICKER_COMPLETE',
  CLOSE_PICKER: 'CLOSE_PICKER',
  ENTER_FULLSCREEN: 'ENTER_FULLSCREEN',
  EXIT_FULLSCREEN: 'EXIT_FULLSCREEN',
  UPLOAD_STARTED: 'UPLOAD_STARTED',
  UPLOAD_COMPLETE: 'UPLOAD_COMPLETE',
  CLOSE_PANEL: 'CLOSE_PANEL',
  SELECT_FILE: 'SELECT_FILE'
};
export const FileExtensionFilterTypes = {
  SUPPORTED: 'SUPPORTED',
  UNSUPPORTED: 'UNSUPPORTED',
  NONE: 'NONE'
};
export const PANEL_WIDTH = 590;
export const PANEL_WIDTH_EXPANDED = PANEL_WIDTH;
export const ThumbnailSizes = {
  ICON: 'icon',
  THUMB: 'thumb',
  PREVIEW: 'preview',
  MEDIUM: 'medium'
};
export const RequestStatus = {
  UNINITIALIZED: 'UNINITIALIZED',
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  NOTFOUND: 'NOTFOUND'
};
// we've historically used the modern convenience definition to display files sizes https://en.wikipedia.org/wiki/Megabyte#Definitions
export const BYTES_IN_MEGABYTE = 1000000;

// Based on https://git.hubteam.com/HubSpot/content4j/blob/5353e2fdfcea0d1c30fc90f0e6d63c7fe70b56fe/ContentModel/src/main/java/com/hubspot/content/model/FileMetadata.java#L959
export const ThumnailSizeToIconSize = {
  [ThumbnailSizes.ICON]: 35,
  [ThumbnailSizes.THUMB]: 100,
  [ThumbnailSizes.PREVIEW]: 240,
  [ThumbnailSizes.MEDIUM]: 600
};

// traditional size for megabytes
const BYTES_IN_MB_FOR_UPLOAD_LIMIT = 1024 * 1024;
// if a file is larger than this threshold, we'll use chunked uploads
export const LARGE_FILE_UPLOAD_SIZE_THRESHOLD = 100 * BYTES_IN_MB_FOR_UPLOAD_LIMIT;

// the size of each part of a large file upload
export const LARGE_FILE_UPLOAD_PART_SIZE = 10 * BYTES_IN_MB_FOR_UPLOAD_LIMIT;