import PortalIdParser from 'PortalIdParser';
import pick from 'FileManagerCore/utils/pick';
import * as Constants from 'FileManagerCore/Constants';
import { QueryParams, TabPaths } from '../Constants';
import getQueryFromLocation from './getQueryFromLocation';
import { parseQueryObject, singularizeQueryParam } from './location';
import { parseSearchString, stringifyQueryObject } from 'FileManagerCore/utils/urls';
const BASE_URL = `/files`;

// OR, if you require portalID:
export const getRootUrl = () => `${BASE_URL}/${PortalIdParser.get()}`;
const PASSTHRU_QUERY_PARAMS = [QueryParams.orderBy, QueryParams.sortDirection, QueryParams.archived, QueryParams.page, QueryParams.fileType, QueryParams.folderId, QueryParams.isHublVideo];
export const SEARCH_QUERY_PARAM_NAME = QueryParams.query;
export const FOLDER_PATH_QUERY_PARAM_NAME = 'folderPath';
export const FILE_DETAILS_QUERY_PARAM_NAME = Constants.FILE_DETAILS_QUERY_PARAM_NAME;
export const LEGACY_FILE_DETAILS_PATH_REGEX = /^\/file-details\/(\d+)/i;
export const LEGACY_FOLDER_PATH_PATH_REGEX = /^\/folder\/(.*)/i;
export const parseLegacyFolderPathFromLocation = location => {
  const match = location.pathname.match(LEGACY_FOLDER_PATH_PATH_REGEX);
  if (match && match.length === 2) {
    return match[1];
  }
  return null;
};
const TRIGGER_REFRESH_URL_PARAMS = ['path', 'search', QueryParams.orderBy, QueryParams.sortDirection, QueryParams.archived, QueryParams.fileType, QueryParams.folderId, QueryParams.isHublVideo];
export const getDashboardUrlParams = ({
  location
}) => {
  const allQueryParams = getQueryFromLocation(location);
  const filteredUrlParams = pick(allQueryParams, PASSTHRU_QUERY_PARAMS);
  const search = singularizeQueryParam(allQueryParams[SEARCH_QUERY_PARAM_NAME]);
  const path = singularizeQueryParam(allQueryParams[FOLDER_PATH_QUERY_PARAM_NAME]);
  return Object.assign({}, filteredUrlParams, {
    search,
    path
  });
};
export const shouldRefreshDashboard = (currentUrlParams, prevUrlParams) => {
  return TRIGGER_REFRESH_URL_PARAMS.some(param => currentUrlParams[param] !== prevUrlParams[param]);
};
export const parseFileIdFromUrlParams = location => {
  const queryParams = parseQueryObject(location);
  const showDetailsIdParam = queryParams[FILE_DETAILS_QUERY_PARAM_NAME];
  if (!showDetailsIdParam) {
    return null;
  }
  const showDetailsId = parseInt(singularizeQueryParam(showDetailsIdParam), 10);
  return showDetailsId > 0 ? showDetailsId : null;
};
export const isUsingLegacyRoute = location => {
  if (parseLegacyFolderPathFromLocation(location)) {
    return true;
  }
  return LEGACY_FILE_DETAILS_PATH_REGEX.test(location.pathname);
};
export const rewriteInitialRoute = history => {
  const currentLocation = history.location;
  const match = currentLocation.pathname.match(LEGACY_FILE_DETAILS_PATH_REGEX);
  if (match && match.length === 2) {
    const fileId = match[1];
    history.replace({
      pathname: '/',
      search: stringifyQueryObject(Object.assign({}, parseSearchString(currentLocation.search || ''), {
        [FILE_DETAILS_QUERY_PARAM_NAME]: fileId
      }))
    });
  }
  const folderPath = parseLegacyFolderPathFromLocation(currentLocation);
  if (folderPath) {
    history.replace({
      pathname: '/',
      search: stringifyQueryObject(Object.assign({}, parseSearchString(currentLocation.search || ''), {
        folderPath
      }))
    });
  }
};
export const getCurrentTab = location => {
  const {
    pathname
  } = location;
  return TabPaths[pathname];
};