import { reportError } from '../utils/logging';
import { SEARCH_FILES_SUCCESS, SEARCH_FILES_FAILED, SEARCH_FILES_ATTEMPTED } from './ActionTypes';
import { RequestStatus } from '../Constants';
import * as FileSearchApi from '../api/FileSearch';
import { trackInteraction, trackOnce } from './tracking';
import { getRecentlyUploadedFiles } from '../selectors/FileDetails';
import { parseFileExtensionFromSearch } from '../utils/parseFileExtensionFromSearch';
import { containsDoubleByteUnicode } from '../utils/stringUtils';
import { buildFilesResponsePayload, mergeRecentlyUploadedFiles } from '../utils/crmSearch';
import { selectIsUngatedForAiPoweredSearch } from '../selectors/Auth';
const searchFilesAttempted = (query, fetchingMore) => ({
  type: SEARCH_FILES_ATTEMPTED,
  status: RequestStatus.PENDING,
  query,
  fetchingMore
});
const searchFilesSuccess = (query, data, fetchingMore) => ({
  type: SEARCH_FILES_SUCCESS,
  status: RequestStatus.SUCCEEDED,
  query,
  data,
  fetchingMore
});
const searchFilesFailed = (query, error) => ({
  type: SEARCH_FILES_FAILED,
  status: RequestStatus.FAILED,
  query,
  error
});
export const getModifiedOptionsWithFileTypeSearch = options => {
  const {
    searchQuery,
    systemRecognizedFileExtension
  } = parseFileExtensionFromSearch(options.search);
  if (systemRecognizedFileExtension) {
    return Object.assign({}, options, {
      search: searchQuery,
      extension: systemRecognizedFileExtension
    });
  }
  return options;
};
export const searchFiles = (options, fetchingMore = false) => (dispatch, getState) => {
  const folderId = options.folder_id;
  const recentlyUploadedFiles = getRecentlyUploadedFiles(getState(), {
    folderId
  });
  const shouldMergeRecentlyUploadedFiles = !fetchingMore && !options.search && !options.archived;
  if (options.search) {
    options = getModifiedOptionsWithFileTypeSearch(options);
  }
  dispatch(searchFilesAttempted(options, fetchingMore));
  if (options.extension) {
    dispatch(trackInteraction('Manage Files', 'search-by-extension', {
      fileExtension: options.extension
    }));
  }
  if (options.search && containsDoubleByteUnicode(options.search)) {
    dispatch(trackOnce('Manage Files', 'search-with-fuzzy-unicode'));
  }
  const useSemanticSearch = selectIsUngatedForAiPoweredSearch(getState());
  return FileSearchApi.fetchFilesFromPublicSearch(options, useSemanticSearch).then(data => {
    let resp = buildFilesResponsePayload(data);
    if (shouldMergeRecentlyUploadedFiles) {
      resp = mergeRecentlyUploadedFiles(resp, recentlyUploadedFiles);
    }
    dispatch(searchFilesSuccess(options, resp, fetchingMore));
  }).catch(err => {
    reportError(err, {
      type: SEARCH_FILES_FAILED
    });
    dispatch(searchFilesFailed(options, err));
    reportError(err, {
      type: 'FETCH_FILES_FROM_PUBLIC_SEARCH_FAILED',
      options
    });
  });
};