import I18n from 'I18n';
import unescapedText from 'I18n/utils/unescapedText';
import { FolderValidationErrors } from '../enums/FolderValidationErrors';
import getDoesStringOnlyContainSpaces from './getDoesStringOnlyContainSpaces';

// eslint-disable-next-line
const invalidFolderCharsRegExp = /[#\?&'";:\*\^!\$\|\/%@]/g;
const FOLDER_NAME_CHAR_LIMIT = 100;
const validateOnlySpaces = folderName => {
  if (getDoesStringOnlyContainSpaces(folderName)) {
    return {
      code: FolderValidationErrors.ONLY_WHITESPACES,
      messageKey: 'FileManagerCore.folderNameValidation.invalidNameOnlyWhitespace'
    };
  }
};
const validateLength = folderName => {
  if (folderName.length > FOLDER_NAME_CHAR_LIMIT) {
    return {
      code: FolderValidationErrors.TOO_LONG,
      messageKey: 'FileManagerCore.folderNameValidation.tooLong'
    };
  }
};
const validateInvalidChars = folderName => {
  const matches = folderName.match(invalidFolderCharsRegExp);
  if (matches) {
    const uniqueMatches = new Set(matches);
    return {
      code: FolderValidationErrors.INVALID_CHARS,
      invalidChars: Array.from(uniqueMatches),
      messageKey: uniqueMatches.has('"') ? 'FileManagerCore.folderNameValidation.invalidCharsSingleQuote' : 'FileManagerCore.folderNameValidation.invalidCharsDoubleQuote'
    };
  }
};
export const validateFolderName = folderName => {
  const validation = validateOnlySpaces(folderName) || validateLength(folderName) || validateInvalidChars(folderName);
  return validation || null;
};

/**
 * @deprecated Use validateFolderName with I18n components.
 */
export const validateFolderName_DEPRECATED = folderName => {
  const validation = validateFolderName(folderName);
  if (!validation) {
    return null;
  }
  if (validation.code === FolderValidationErrors.INVALID_CHARS) {
    return {
      code: validation.code,
      message: unescapedText(validation.messageKey, {
        invalidChars: validation.invalidChars.join('')
      })
    };
  }
  return {
    code: validation.code,
    message: I18n.text(validation.messageKey)
  };
};
export default validateFolderName_DEPRECATED;