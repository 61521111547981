import { fromJS, Map as ImmutableMap } from 'immutable';
import { RequestStatus } from '../../Constants';
const defaultState = {
  selectedVideoFileId: undefined,
  updateSubtitlesRequestStatus: RequestStatus.UNINITIALIZED,
  fetchSubtitlesRequestStatusByVideoFileId: ImmutableMap(),
  fetchGenerationOptInRequestStatus: RequestStatus.UNINITIALIZED,
  locallyLoadedSubtitles: ImmutableMap(),
  generationEnabled: null
};
export const defaultSubtitlesState = fromJS(defaultState);