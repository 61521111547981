import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'data-fetching-client';
import { SEARCH_FOLDERS } from './queries';
import { validateFolderName } from 'FileManagerCore/utils/validateFolderName';
import { FolderValidationErrors } from 'FileManagerCore/enums/FolderValidationErrors';
const MINIMUM_FOLDER_FETCH_LIMIT = 40;
export const useFolderNameValidation = ({
  previousName,
  parentFolderId
}) => {
  const [validationMessage, setValidationMessage] = useState();
  const [name, setName] = useState(previousName || '');
  const trimmedName = name.trim();
  const {
    data
  } = useQuery(SEARCH_FOLDERS, {
    variables: {
      parent_folder_id: parentFolderId !== null && parentFolderId !== void 0 ? parentFolderId : 'None',
      limit: MINIMUM_FOLDER_FETCH_LIMIT
    },
    fetchPolicy: 'no-cache'
  });
  const existingFolderNames = useMemo(() => {
    const nameSet = new Set();
    data === null || data === void 0 || data.searchFolders.results.forEach(result => nameSet.add(result.name));
    if (previousName) {
      nameSet.delete(previousName);
    }
    return nameSet;
  }, [data, previousName]);
  const validateName = () => {
    if (trimmedName === previousName) {
      return setValidationMessage(undefined);
    }
    if (existingFolderNames.has(trimmedName)) {
      return setValidationMessage({
        messageKey: 'FileManagerDashboard.folderNameValidation.existingFolder',
        code: FolderValidationErrors.DUPLICATE
      });
    }
    return setValidationMessage(validateFolderName(name) || undefined);
  };
  useEffect(validateName, [existingFolderNames, name, trimmedName, previousName]);
  const isValidChange = !validationMessage && trimmedName.length > 0 && trimmedName !== previousName;
  return {
    name,
    setName,
    isValidChange,
    validationMessage
  };
};