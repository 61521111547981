import { DefaultQuery, QueryParams } from '../Constants';
import OrderByField from '../enums/OrderByField';
import { SortDirection } from '../enums/SortDirection';
import { parseFileTypeFromLocation, parseFolderIdFromLocation, parseQueryObject, singularizeQueryParam } from './location';
function parseOrderBy(queryObj) {
  const {
    orderBy
  } = queryObj;
  const orderByString = singularizeQueryParam(orderBy);
  if (!orderByString || !Object.values(OrderByField).includes(orderByString)) {
    return DefaultQuery.get(QueryParams.orderBy);
  }
  return orderByString;
}
function parseSortDirection(queryObj) {
  const {
    sortDirection
  } = queryObj;
  const sortDirectionString = singularizeQueryParam(sortDirection);
  if (!sortDirectionString || !Object.values(SortDirection).includes(sortDirectionString)) {
    return DefaultQuery.get(QueryParams.sortDirection);
  }
  return sortDirectionString;
}
function parseArchived(queryObj) {
  return singularizeQueryParam(queryObj.archived) === 'true';
}
function parsePage(queryObj) {
  const {
    page
  } = queryObj;
  const pageString = singularizeQueryParam(page);
  if (!pageString) {
    return 1;
  }
  return parseInt(pageString, 10);
}
export default function getQueryFromLocation(location) {
  if (!location) {
    return DefaultQuery.toJS();
  }
  const queryObj = parseQueryObject(location);
  return Object.assign({}, queryObj, {
    page: parsePage(queryObj),
    archived: parseArchived(queryObj),
    orderBy: parseOrderBy(queryObj),
    sortDirection: parseSortDirection(queryObj),
    fileType: parseFileTypeFromLocation(location),
    folderId: parseFolderIdFromLocation(location)
  });
}